var $body = $("body");
//var $favToggle = $(".favourites");
var $favContainer = $("header").find("#favourites-container");
var $close = $favContainer.find(".close-slide");

var $cartToggle = $(".cart");
var $cartContainer = $("header").find("#cart-container");

var $addToCart = $(".add-to-cart");
var $addToCartQuantity = $(".add-to-cart-quantity");

/*$favToggle.on("click", function () {
   "use strict";
   $cartContainer.removeClass("show");
   $favContainer.toggleClass("show");
   $body.toggleClass("overlay");
});*/

$close.on("click", function () {
    "use strict";
    if ($favContainer.hasClass("show")) {
        $favContainer.removeClass("show");
    }
    if ($body.hasClass("overlay")) {
        $body.removeClass("overlay");
    }
});

// $cartToggle.on("click", function () {
//     "use strict";
//     $favContainer.removeClass("show");
//     $cartContainer.toggleClass("show");
//     $body.toggleClass("overlay");
// });

var $closeCart = $cartContainer.find(".close-slide");
$closeCart.on("click", function () {
    "use strict";
    if ($cartContainer.hasClass("show")) {
        $cartContainer.removeClass("show");
    }
    if ($body.hasClass("overlay")) {
        $body.removeClass("overlay");
    }
});

var $accordionToggle = $("#accordionDelivery .card .card-header").find(".toggle");
var $radioButtonCheck = $accordionToggle.find(".radio-button > .radio-inner");
$accordionToggle.on("click", function () {
    "use strict";
    var $radioButton = $(this).find(".radio-inner");
    $radioButtonCheck.removeClass("checked");
    if ($radioButtonCheck.hasClass("checked")) {
        $radioButtonCheck.removeClass("checked");
    } else {
        $radioButton.addClass("checked");
    }
});

var $accordionPaymentToggle = $("#accordionPayment .card .card-header").find(".toggle");
var $radioButtonCheckPayment = $accordionPaymentToggle.find(".radio-button > .radio-inner");
$accordionPaymentToggle.on("click", function () {
    "use strict";
    var $radioButton = $(this).find(".radio-inner");
    $radioButtonCheckPayment.removeClass("checked");
    if ($radioButtonCheckPayment.hasClass("checked")) {
        $radioButtonCheckPayment.removeClass("checked");
    } else {
        $radioButton.addClass("checked");
    }
});

var $paymentToggle = $("#checkout").find(".payment-toggle");
var $paymentContainer = $("#checkout").find("#order-details");
$paymentToggle.on("click", function () {
    $(this).toggleClass("toggle");
    $paymentContainer.toggleClass("expanded");
    $body.toggleClass("overflow-hidden");
});

(function() {
    'use strict';
    window.addEventListener('load', function() {
        var forms = document.getElementsByClassName('needs-validation');
        var validation = Array.prototype.filter.call(forms, function(form) {
            form.addEventListener('submit', function(event) {
                if (form.checkValidity() === false) {
                    event.preventDefault();
                    event.stopPropagation();
                    $(form).addClass('has-errors');
                } else {
                    $(form).removeClass('has-errors');
                    $(form).addClass('submitting');
                }
                form.classList.add('was-validated');
            }, false);
        });
    }, false);
})();

var DataLayer = {
    addToCart: function(productData) {
        window.dataLayer  = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'eec.addToCart',
            'ecommerce': {
                'currencyCode': 'EUR',
                'add': {
                    'actionField': {
                        'list': 'main'
                    },
                    'products': [productData]
                }
            }
        });
    },
    removeFromCart: function(productData) {
        window.dataLayer  = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'eec.removeFromCart',
            'ecommerce': {
                'currencyCode': 'EUR',
                'remove': {
                    'products': [productData]
                }
            }
        });
    },
    checkout: function(products) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'eec.checkout',
            'ecommerce': {
                'checkout': {
                    'actionField': {
                        'step': 1
                    },
                    'products': products
                }
            }
        });
    },
    checkout2: function(payment) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'eec.checkout',
            'ecommerce': {
                'checkout': {
                    'actionField': {
                        'step': 2,
                        'option': payment
                    }
                }
            }
        });
    },
    purchase: function(purchaseData) {
        window.dataLayer  = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'eec.purchase',
            'ecommerce': {
                'purchase': purchaseData
            }
        });
    }
};

var Cart = function (container, type) {
    this._body = $("body");
    this._addButton = $(".add-to-cart-button");
    this._addQuantityInput = $(".add-to-cart-quantity");
    this._addOptions = $(".product-options");
    this._cartContainer = container;
    this._type = type;
    this.initialize();
};
Cart.prototype = {
    _body: null,
    _addButton: null,
    _addOptions: null,
    _addQuantityInput: null,
    _changeTimeout: null,
    _changeRequest: null,
    _cartContainer: null,
    _type: null,
    initialize: function () {

        var self = this;

        if (this._type == 'full') {
            $.get('/cart/cart?type=full', {}, function (html) {
                self.openWithHTML(html);
            });
        }
        if (this._type == 'side') {
            this._addButton.on('click', function () {
                var options = [];
                var optionsNames = [];

                if (self._addOptions.length > 0) {
                    var valid = true;
                    self._addOptions.each(function () {
                        if ($(this).find('input:checked').val() != null) {
                            options.push($(this).find('input:checked').val());
                            var label = $("label[for='" + $(this).find('input:checked').attr('id') + "']");
                            optionsNames.push(label.html().trim());
                        } else {
                            $(this).addClass('error');
                            valid = false;
                        }
                    });

                    if (!valid) {
                        return false;
                    }
                }

                var button = $(this);

                $.post('/cart/add', {
                    id: $(this).data('id'),
                    qty: self._addQuantityInput.length == 0 ? 1 : self._addQuantityInput.val(),
                    options: options,
                    mobile: button.hasClass('add-to-cart-mobile')
                }, function (html) {
                    if (button.hasClass('add-to-cart-mobile')) {
                        $("#addToCartPopup").html(html).modal();
                    } else {
                        self.openWithHTML(html);
                    }
                    // ADD PRODUCT DATA TO DATALAYER
                    var productData = button.data("data");
                    productData.quantity = $(".add-to-cart-quantity").val();
                    productData.variant = optionsNames.join(", ");
                    DataLayer.addToCart(productData);
                });

                return false;
            });

            this._addOptions.find('input').on('click', function () {
                $(this).parents('.product-options.error').removeClass('error');
            });

            self._body.on('click', '.add-to-cart-list', function () {

                var button = $(this);

                $.post('/cart/add', {
                    id: $(this).parents('.product').data('product'),
                    qty: 1,
                    options: []
                }, function (html) {

                    if (button.hasClass('add-to-cart-mobile')) {
                        $("#addToCartPopup").modal();
                    } else {
                        self.openWithHTML(html);
                    }

                });

                return false;
            });

            self._body.find('.side-cart-overlay').on('click', function () {
                self._body.removeClass('overlay');
                self._cartContainer.removeClass('show');
            });
        }
    },

    openWithHTML: function (html) {

        var self = this;

        if (this._type == 'side') {
            self._body.addClass('overlay');
        }
        self._cartContainer.html(html).addClass('show');

        self._cartContainer.find('.close-slide').on('click', function () {
            self._body.removeClass('overlay');
            self._cartContainer.removeClass('show');
        });

        self._cartContainer.find('.btn-remove').on('click', function () {
            var product = $(this).parents('.product');

            var options = product.data('options') == '' ? [] : product.data('options').split(';');

            self.change('remove', {
                    id: product.data('id'),
                    options: options
                },
                $(this));
            var productData = product.data("data");
            productData.quantity = product.find("#quantity").val();
            productData.variant = product.find(".option").html();
            DataLayer.removeFromCart(productData);

            return false;
        });

        self._cartContainer.find('.btn-plus').on('click', function () {
            var product = $(this).parents('.product');
            var qty = product.find('.qty');

            qty.val(parseInt(qty.val())+1).trigger('blur');

            var productData = product.data("data");
            productData.quantity = 1;
            productData.variant = product.find(".option").html();
            DataLayer.addToCart(productData);

            return false;
        });

        self._cartContainer.find('.btn-minus').on('click', function () {
            var product = $(this).parents('.product');
            var qty = product.find('.qty');

            qty.val(parseInt(qty.val()-1)).trigger('blur');

            var productData = product.data("data");
            productData.quantity = 1;
            productData.variant = product.find(".option").html();
            DataLayer.removeFromCart(productData);

            return false;
        });

        self._cartContainer.find('.qty').on('blur', function () {
            var product = $(this).parents('.product');

            var options = product.data('options') == '' ? [] : product.data('options').split(';');

            self.change('qty', {
                id: product.data('id'),
                qty: product.find('.qty').val(),
                options: options
            }, $(this));

            return false;
        });
    },
    change: function(action, data, element) {
        var self = this;

        if (self._changeRequest != null) {
            self._changeRequest.abort();
        }
        if (self._changeTimeout != null) {
            clearTimeout(self._changeTimeout);
        }

        self._changeTimeout = setTimeout(function () {

            element.parents('.product').find(".quantity").prepend('<div class="loading"><div class="arc"></div><div class="arc"></div><div class="arc"></div></div>');

            self._cartContainer.find('.cart-overlay').show();

            self._changeRequest = $.post('/cart/' + action + '?type=' + self._type, data, function (html) {
                self.openWithHTML(html);

                getStatus();
            });
        }, 600);
    }
};

var fullCartPlaceholder = $(".full-cart-placeholder");
var sideCartPlaceholder = $(".side-cart-placeholder");
if (fullCartPlaceholder.length > 0) {
    fullCartPlaceholder.prepend('<div class="loading"><div class="arc"></div><div class="arc"></div><div class="arc"></div></div>');
    new Cart(fullCartPlaceholder, 'full');
} else {
    new Cart($(".side-cart-placeholder"), 'side');
}

getStatus();

function getStatus() {
    $.get('/cart/status', function (response) {
        var data = JSON.parse(response);

        if (data.cart > 0) {
            $(".cart-link").addClass("active");
        } else {
            $(".cart-link").removeClass("active");
        }

        if (data.user != null) {
            $(".user-login").addClass("active");
            $(".user-login").attr("title","Moj profil");
        } else {
            $(".user-login").removeClass("active");
            $(".user-login").attr("title","Prijavi se");
        }
    });
}

var Checkout = function (container, type) {
    this.initialize();
};
Checkout.prototype = {
    initialize: function () {
        var self = this;
        if ($("#account-checkbox").is(':checked')) {
            $(".form-password").show().find('input').prop('required', true);
        }

        $("#account-checkbox").on('click', function () {
            if ($(this).is(':checked')) {
                $(".form-password").show().find('input').prop('required', true);
            } else {
                $(".form-password").hide().find('input').prop('required', false);
            }
        });

        $(".delivery-address").find('input').prop('required', false);
        $("#delivery-checkbox").on('click', function () {
            if ($(this).is(':checked')) {
                $(".delivery-address").show().find('input').prop('required', true);
            } else {
                $(".delivery-address").hide().find('input').prop('required', false);
            }
        });

        $(".address-card").on('click', function () {
            $(".address-card.active.selected").removeClass(['active', 'selected']);

            $(this).addClass(['active', 'selected']);

            var address = $(this).data('address');

            $(".delivery-address input[name=delivery_address_id]").val(address.id);

            Object.keys(address).forEach(function(key) {
                $(".delivery-address input[name=delivery_" + key + "]").val(address[key]);
            });
        });

        $.get("/cart/checkout-summary", function (html) {
            self.applySummary(html);
            var productsData = [];

            $("#order-details .product").each(function() {
                var product = $(this);
                var productData = product.data("data");
                productData.quantity = product.find(".quantity").data("quantity");
                productData.variant = product.find(".option").length > 0 ? product.find(".option").html() : '';
                productsData.push(productData);
            });
            DataLayer.checkout(productsData);
        });
    },
    applySummary: function(html) {
        var self = this;
        $(".checkout-summary-placeholder").html(html);

        $(".coupon-form").on('submit', function () {
            var code = $("#discount-code").val();

            $.post('/cart/checkout-coupon', { code: code }, function (html) {
                self.applySummary(html);
            });

            return false;
        });

        $(".coupon-remove").on('click', function () {

            $.get('/cart/checkout-coupon-remove', function (html) {
                self.applySummary(html);
            });

            return false;
        });
    }
};

if ($("#checkout").length > 0) {
    new Checkout();
}

if ($("#order-finalize.success").length > 0) {
    var purchaseData = $("#order-finalize.success").data("data");
    var orderId = purchaseData.actionField.id;
    if(!lyteeGetCookie("order-" + orderId)) {
        DataLayer.purchase(purchaseData);
        //set cookie
        var d = new Date();
        d.setTime(d.getTime() + (365 * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = ("order-" + orderId) + "=true;" + expires + ";path=/";
    }
}

$("#paymentTypeAccordion input").on("change", function() {
    var selected_value = $("#paymentTypeAccordion input[name='payment_type_id']:checked");
    var payment = selected_value.closest(".form-check-label").find(".radio_label").html();
    DataLayer.checkout2(payment);
});

var addToCartCont = $(".data-container").find(".add-to-cart-container");
var quantity = addToCartCont.find(".add-to-cart-quantity");
var price = addToCartCont.find(".product-price");
var totalPrice = $(".cart").find(".total-price");

var priceToCalculate = new Intl.NumberFormat('en-US', {}).format(price.val());

quantity.on("change", function (e) {
    console.log("change");
    e.preventDefault();
    if (quantity.val() <= 0) {
        $("#quantityErrorModal").show();
        quantity.val(1);
    }
    var sum = (parseFloat(priceToCalculate) * parseInt(quantity.val())).toFixed(2);
    sum = new Intl.NumberFormat('sl-SI', { style: 'currency', currency: 'EUR' }).format(sum);
    totalPrice.text(sum);
})

var closeQuantityModal = $("#quantityErrorModal").find(".close");
closeQuantityModal.on("click", function () {
    $("#quantityErrorModal").hide();
})

